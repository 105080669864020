import { SoundItem } from "./types"
import { Pause, Play } from "./Icons"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Track } from "./Track"

const formatSeconds = (seconds: number): string => {
	return `${Math.floor(seconds / 60)}:` + `${Math.floor(seconds) % 60}`.padStart(2, "0")
}

export const Player = ({
	sound
}: {
	sound?: SoundItem
}) => {
	const audioRef = useRef<HTMLAudioElement>(null)
	const [isPlaying, setPlaying] = useState(false)
	const [progress, setProgress] = useState(0)
	const audio = useMemo(() => audioRef?.current, [audioRef.current])

	const handlePause = useCallback(() => {
		setPlaying(false)
	}, [audio])

	const handlePlay = useCallback(() => {
		setPlaying(true)
	}, [audio])

	const handlePlayPause = useCallback(() => {
		if (audio) {
			if (isPlaying) {
				audio.pause()

				setPlaying(false)
			} else {
				audio
					.play()
					.then(() => setPlaying(true))
			}
		}
	}, [audio, isPlaying])

	const handleSkip = useCallback((to: number) => {
		if (audio)
			audio.currentTime = audio.duration * to
	}, [audio])

	const handleTime = useCallback(() => {
		if (audio) {
			setProgress(audio.currentTime)
		}
	}, [audio])

	// set listeners
	useEffect(() => {
		if (audio) {
			audio.addEventListener("pause", handlePause)
			audio.addEventListener("play", handlePlay)
			audio.addEventListener("timeupdate", handleTime)

			// remove listeners
			return () => {
				audio.removeEventListener("pause", handlePause)
				audio.removeEventListener("play", handlePlay)
				audio.removeEventListener("timeupdate", handleTime)
			}
		}
	}, [audio])

	// set new song
	useEffect(() => {
		if (audio && sound?.url && sound.url !== audio.src)
			audio.src = sound.url
	}, [audio, sound])

	return (
		<div className="playerHolder column" style={{ gap: 5 }}>
			{sound && (
				<span style={{
					fontFamily: "monospace",
					paddingRight: 30
				}}>
					{sound.name}
					{sound.artist ? ` - ${sound.artist}` : ""}
				</span>
			)}

			<div className="player row" style={{ gap: 5, alignItems: "center" }}>
				<Button onClick={handlePlayPause}>
					{isPlaying ? <Pause /> : <Play />}
				</Button>

				<Track
					currentTime={progress}
					duration={audio?.duration}
					onSkip={handleSkip}
					sound={sound}
				/>

				<audio
					controls
					autoPlay
					style={{ display: "none" }}
					ref={audioRef}
				/>
			</div>

			<span
				style={{
					fontFamily: "monospace",
					paddingRight: 30
				}}>
				{sound ? `${formatSeconds(progress)} / ${formatSeconds(audio?.duration || 0)}` : "\u00A0"}
			</span>
		</div>
	)
}

const Button = ({
	children,
	onClick
}: {
	children: React.ReactNode,
	onClick: () => void
}) =>
	<div
		onClick={onClick}
		style={{
			height: 40,
			width: 40,
			background: "black",
			borderRadius: "50%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		}}>
		{children}
	</div>

