import { SoundItem } from "./types"
import { Download, IconAction, Play } from "./Icons"
import React from "react"

const ITEMS: SoundItem[] = [
	{
		name: "Track 0"
	},
	{
		artist: "PBR",
		name: "Best track ever",
		url: "after-hours.mp3"
	},
	{
		name: "Another song",
		url: "house-vibes.mp3"
	}
]

export const Songlist = ({ setPlaying }: { setPlaying: (song: SoundItem) => void }) => (
	<div className="itemHolder">
		{ITEMS
			.map(item => (
				<div className="row soundItem" key={item.name} onClick={() => item.url && setPlaying(item)}>
					<div className={"row"}>
						{item.url && <Play />}
						<h3>{item.name}</h3>
					</div>

					{item.url && (
						<div className="column">
							<IconAction icon={<Download />} url={item.url} />
						</div>
					)}
				</div>
			))}
	</div>
)
