import { SoundItem } from "./types"
import React from "react"

const TRACK_WIDTH = 350

export const Track = ({
	currentTime,
	duration,
	onSkip,
	sound
}: { currentTime?: number, duration?: number, onSkip: (to: number) => void, sound?: SoundItem }) => (
	<div
		onClick={({ pageX, target }) => {
			const { x } = (target as HTMLDivElement).getBoundingClientRect()

			onSkip((pageX - x) / TRACK_WIDTH)
		}}
		style={{
			height: sound ? 40 : 4,
			width: TRACK_WIDTH,
			background: "white",
			border: "3px solid black",
			boxSizing: "border-box",
			borderRadius: 20,
			overflow: "hidden",
			transition: "height 200ms ease"
		}}
	>
		{duration && currentTime && <div
			style={{
				background: "black",
				height: "100%",
				width: `${(currentTime / (duration || 133769420)) * 100}%`,
				border: "2px solid black",
				boxSizing: "border-box",
				transform: "scale(1.1)",
				borderRadius: 69
			}}
		/>}
	</div>
)
