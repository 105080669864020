import React, { useState } from "react"
import { SoundItem } from "./types"
import { Player } from "./Player"
import { Songlist } from "./Songlist"

function App() {
	const [playing, setPlaying] = useState<SoundItem | undefined>()

	return (
		<div className="holder">
			<header>
				Soundscapes
			</header>

			<Songlist setPlaying={setPlaying} />

			<Player sound={playing} />
		</div>
	)
}

export default App
