import React, { ReactElement } from "react"

export const Download = () => <i className="fa-solid fa-download"></i>

export const IconAction = ({
	icon,
	onClick,
	url,
}:
	{
		icon: ReactElement,
		onClick?: () => void,
		url?: string,
	}) => <a
	href={url}
	onClick={onClick}>
	{icon}
</a>

export const Play = () => <i className="fa-solid fa-play"></i>

export const Pause = () => <i className="fa-solid fa-pause"></i>

export const Stop = () =>
	<i className="fa-solid fa-stop"></i>
